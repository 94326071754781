import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerObject,
  ExternalLink
} from './bannerSection.style';

import RulesView from 'common/src/assets/image/saas/ab-rules.png';
import HomeView from 'common/src/assets/image/saas/ab-home.png';
import ActivationView from 'common/src/assets/image/saas/ab-activation.png';
import SuggestSegmentsView from 'common/src/assets/image/saas/ab-suggest-segments.png';

const AudienceBuilderSection = ({
  row,
  col,
  title,
  description,
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="Audience Builder"
                  {...title}
                />
              }
              description={
                <div>
                  <Text
                    content="Generate audiences and segmentation rules using Generative AI. Audience and rule selection is based on customer data features and campaign context including goals, target product information, and campaign features. Generated rules can be edited and refined keeping humans in the loop."
                    {...description}
                  />
                  <Text/>
                  <Image src={HomeView} alt="Single View of Audiences" />
                  <Image src={ActivationView} alt="Activate Channel Systems" />
                  <Image src={SuggestSegmentsView} alt="AI Generated Segments" />
                </div>
              }
            />
          </Box>
        </Box>
      </Container>
      <BannerObject style={{ alignItems: 'flex-start', top: 157}}>
        <div className="objectWrapper">
          <Image src={RulesView} alt="AI Generated Segmentation Rules" />
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

AudienceBuilderSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

AudienceBuilderSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
};

export default AudienceBuilderSection;
